<template>
  <section class="app-container" ref="italentReportRef">
    <div>
      <a-modal
        :getContainer="()=>$refs.italentReportRef"
        width="20.8vw"
        title="需求同步"
        okText="确定"
        cancelText="取消"
        v-model:visible="visible"
        @ok="handleOk"
        centered
      >
        <div>
          <span>北森报表名称：</span>
          <a-select
            style="width: 240px"
            :options="ITalent.options"
            v-model:value="ITalent.value"
          />
        </div>
      </a-modal>
    </div>
    <div class="mb12">
      <FilterLayout
        @doReset="resetForm"
        @doSearch="handleSearch"
        ref="filterLayoutRef"
        @doCollapseEffect="getChartContainerStyle"
        @handleFilter="handleFilter"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=3">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceholder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        :tag-count="item.inputDataType==='requirementStatusList'?2:1"
                    >
                    </SelectWithAll>
                  </template>
                  <template v-if="item.type==='6'">
                    <a-range-picker
                        v-model:value="formState.dateValueList"
                        format="YYYY-MM-DD"
                        valueFormat="YYYY-MM-DD"
                    />
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>3">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='2'">
                      <a-select
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          allowClear
                          show-search
                      >
                      </a-select>
                    </template>
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          :placeholder="getPlaceholder(item.inputDataType)"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          :tag-count="item.inputDataType==='requirementStatusList'?2:1"
                      >
                      </SelectWithAll>
                    </template>
                    <template v-if="item.type==='6'">
                      <a-range-picker
                          v-model:value="formState.dateValueList"
                          format="YYYY-MM-DD"
                          valueFormat="YYYY-MM-DD"
                      />
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <a-button
                class=""
                ghost
                v-auth="['system:infoMaintain:italentSync']"
                type="primary"
                @click="visible = true"
            >
              需求同步
            </a-button>
            <Tooltip title="导出">
              <a-button class="ml12" type="primary" ghost @click="exportClick">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
    <div style="position: relative">
      <a-table
        ref="reportList"
        class="table-wrap"
        :columns="columns"
        :data-source="data.dataSource"
        rowKey="id"
        :scroll="{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="pagination"
      >
        <template #status="{ record }">
          <a-tag :class="`status ${record.requirementStatus}`">
            {{record.requirementStatus}}
          </a-tag>
        </template>
      </a-table>
      <div class="message" v-if="dataBoxShow">
        <span class="text">报表名称:</span>
        {{ data.message ? data.message : "无" }}
        <span class="text">, 状态:</span>
        {{ data.status ? data.status : "无" }}
        <span class="text">, 获取条数:</span>
        {{ data.count ? data.count : 0 }}
        <span class="text">, 操作时间:</span>
        {{ data.opreationPeo ? data.opreationPeo : "无" }}
      </div>
    </div>
    <div class="message1" v-if="noDataShow">
      <span class="text">报表名称:</span>
      {{ data.message ? data.message : "无" }}
      <span class="text">, 状态:</span>
      {{ data.status ? data.status : "无" }}
      <span class="text">, 获取条数:</span>
      {{ data.count ? data.count : 0 }}
      <span class="text">, 操作时间:</span>
      {{ data.opreationPeo ? data.opreationPeo : "无" }}
    </div>
    <FormModalBox v-model:visible="isShowFormSortBox" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>

<script>
// import { defineComponent, onActivated, onMounted, reactive, ref } from "@vue/runtime-core";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";
import { ExportOutlined } from "@ant-design/icons-vue";
import {
  defineComponent,
  onActivated,
  onMounted,
  reactive,
  ref,
  nextTick,
  onUnmounted,
} from "vue";
import {useTableHeight, downloadFile, dictionaryOpt} from "@/utils/common";
import {cloneDeep} from "lodash";

import Tooltip from '@/components/Tooltip'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
export default defineComponent({
  components: {ExportOutlined,MoreFormList,FormModalBox,Tooltip },
  name: "ItalentReport",
  setup() {
    const { $api } = useGlobalPropertyHook();
    const condition = ref("");
    const reportList = ref("");
    const data = reactive({
      message: "",
      status: "",
      count: "",
      opreationPeo: "",
      dataSource: [],
    });
    const dataBoxShow = ref(true);
    const noDataShow = ref(false);
    const resData = ref("");
    let initData = {
      condition: "",
      dateValueList: [],
      beginDate: "",
      endDate: "",
      demandDeptNameList: [],
      requirementStatusList: [],
      rmChooseTeamList: [],
      completeChannelList: [],
    }
    const formState = reactive({...initData});
    const columns = [
      {
        title: "需求编号",
        dataIndex: "demandNumber",
        key: "demandNumber",
        width: 70,
        ellipsis: true,
      },
      {
        title: "需求部门",
        dataIndex: "demandDeptName",
        key: "demandDeptName",
        width: 80,
        ellipsis: true,
      },
      {
        title: "是否储备招聘",
        dataIndex: "isReserveRecruit",
        key: "isReserveRecruit",
        width: 80,
        ellipsis: true,
      },
      {
        title: "详细客户名称",
        dataIndex: "customerNameDetail",
        key: "customerNameDetail",
        width: 80,
        ellipsis: true,
      },
      {
        title: "客户服务经理(CSM)",
        dataIndex: "csm",
        key: "csm",
        width: 150,
        ellipsis: true,
        customRender: (item) => item.text ? item.text.split('(').join("\n").split(')') : null,
        customCell:() => {
            return {
              style: {
                lineHeight: 1.2,
                padding:'6px 10px',
              }
            }
          }
      },
      {
        title: "需求状态",
        dataIndex: "requirementStatus",
        key: "requirementStatus",
        width: 80,
        ellipsis: true,
        slots: { customRender: "status" },
      },
      {
        title: "RM选择资源团队",
        dataIndex: "rmChooseTeam",
        key: "rmChooseTeam",
        width: 80,
        ellipsis: true,
      },
      {
        title: "完成渠道-需求",
        dataIndex: "completeChannel",
        key: "completeChannel",
        width: 80,
        ellipsis: true,
      },
      {
        title: "排序到岗时间",
        dataIndex: "sortingArrivalTime",
        key: "sortingArrivalTime",
        width: 80,
        ellipsis: true,
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(pagination);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(pagination);
      },
    });

    let currentState = {};
    const updateParams = () => {
      currentState = cloneDeep(formState);
    };
    const resetForm = () => {
      Object.assign(formState,initData)
      updateParams();
      doSearch();
    };
    const demandDeptNameListOps = ref([]);
    const requirementStatusListOps = ref([]);
    const rmChooseTeamListOps = ref([]);
    const completeChannelListOps = ref([]);

    const handleSearch = () => {
      updateParams();
      doSearch();
    };
    const doSearch = ({ current = 1, pageSize = pagination.pageSize } = {}) => {
      let beginDate, endDate;
      beginDate = currentState.dateValueList
        ? currentState.dateValueList[0]
        : "";
      endDate = currentState.dateValueList ? currentState.dateValueList[1] : "";
      $api
        .getItalentReport({
          pageIndex: current,
          pageSize: pageSize,
          condition: currentState.condition,
          demandDeptNameList: currentState.demandDeptNameList,
          requirementStatusList: currentState.requirementStatusList,
          rmChooseTeamList: currentState.rmChooseTeamList,
          completeChannelList: currentState.completeChannelList,
          dateValueList: currentState.dateValueList,
          beginDate,
          endDate,
        })
        .then((res) => {
          pagination.current = res.data?.pageIndex + 1;
          pagination.pageSize = res.data?.pageSize;
          pagination.total = res.data?.total;
          data.dataSource = res.data?.resource || [];
          //判断是否有数据,控制显示左下角控制栏的位置
          if (res.data.total == 0) {
            dataBoxShow.value = false;
            noDataShow.value = true;
          } else {
            dataBoxShow.value = true;
            noDataShow.value = false;
          }
        });
      $api
        .getItalentMessage({
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          condition: currentState.condition,
        })
        .then((res) => {
          resData.value = res.data || "";
          data.message = resData.value.split("：")[1].split("，")[0];
          data.status = resData.value.split("：")[2].split("，")[0];
          data.count = resData.value.split("：")[3].split("，")[0];
          data.opreationPeo = resData.value.split("：")[4];
        });
    };
    //导出数据
    const exportClick = () => {
      updateParams();
      currentState.beginDate = currentState.dateValueList
        ? currentState.dateValueList[0]
        : "";
      currentState.endDate = currentState.dateValueList
        ? currentState.dateValueList[1]
        : "";
      //console.log(currentState.condition)
      $api.getItalentExport(currentState).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    onActivated(() => {
      doSearch();
    });
    const visible = ref(false);
    const ITalent = reactive({
      options: [],
      value: "",
    });
    const handleOk = () => {
      $api
        .syncItalentReport({
          reportName: ITalent.value,
        })
        .then(() => {
          message.success("开始同步报表");
          visible.value = false;
          doSearch();
        });
    };
    const handleFilter = (value) => {
      console.log("vali", value);
    };
    const tableHeight = ref(0);
    const isShowHeight = ref(false)
    const getChartContainerStyle = () => {
      isShowHeight.value = !isShowHeight.value
      getSize();
    };
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };
    const init = () => {
      window.addEventListener("resize", getSize);
      getSize();
      getChartContainerStyle()
    };
    // 获取下拉框数据
    const getSelectOptions = () => {
      $api.getDropdownBoxData().then((res) => {
        const {
          demandDeptNameList,
          requirementStatusList,
          rmChooseTeamList,
          completeChannelList,
        } = res.data;
        demandDeptNameListOps.value = dictionaryOpt(demandDeptNameList);
        requirementStatusListOps.value = dictionaryOpt(requirementStatusList);
        rmChooseTeamListOps.value = dictionaryOpt(rmChooseTeamList);
        completeChannelListOps.value = dictionaryOpt(completeChannelList);
      });
    };

    window.addEventListener("resize", getSize);

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'basicDataManagement',
        secondMenu: 'beisen',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 4?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getPlaceholder = (data) => {
      switch (data) {
        case 'condition':
          return '需求编号/详细客户名称/CSM'
        default :
          return '请输入'  // 如果没
      }
    }
    const getOptions = (data)=> {
      switch (data) {
        case 'demandDeptNameList':
          return demandDeptNameListOps.value
        case 'requirementStatusList':
          return requirementStatusListOps.value
        case 'rmChooseTeamList':
          return rmChooseTeamListOps.value
        case 'completeChannelList':
          return completeChannelListOps.value
        default :
          ''  // 如果没
      }
    }
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const moreSeachHanlder = () => {
      handleSearch()
      closeMoreForm()
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }

    onMounted(() => {
      init();
      $api.getItalentReportName().then((res) => {
        ITalent.options = res.data.map((item) => ({
          label: item,
          value: item,
        }));
        if (ITalent.options.length) ITalent.value = ITalent.options[0].value;
      });
      getSelectOptions();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });

    return {
      formState,
      condition,
      reportList,
      resetForm,
      doSearch,
      data,
      columns,
      dataBoxShow,
      noDataShow,
      pagination,
      handleOk,
      visible,
      ITalent,
      tableHeight,
      updateParams,
      handleSearch,
      exportClick,
      demandDeptNameListOps,
      requirementStatusListOps,
      rmChooseTeamListOps,
      completeChannelListOps,
      getSize,
      getChartContainerStyle,
      handleFilter,
      isShowHeight,
      queryFormList,
      queryList,
      checkList,
      getPlaceholder,
      getOptions,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },
});
</script>

<style scoped lang="less">
@import "~@/style/aModal.less";
.app-container {
  .message {
    position: absolute;
    bottom: 30px;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    .text {
      color: #b3b3b3;
    }
  }
  .message1 {
    position: absolute;
    margin-top: 20px;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    .text {
      color: #b3b3b3;
    }
  }
  .status{
    font-size: 0.729vw;
    padding: 0.1vw 0.86vw;
    border: 0;

    &.进行中 {
      color: #58B99E;
      background: #DDF8F0 60%;
    }
    &.已完成 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已关闭 {
      color: #999999;
      background: #CCCCCC 60%;
    }
  }
  // .form{
  //   margin-left: -30px;
  // }
  // :deep(button) {
  //   margin-left: 12px;
  // }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
